import { CloseIcon } from "@chakra-ui/icons"
import { Box, Center, Divider, HStack, Icon, IconButton, Skeleton, Stack, Text, useClipboard, useTheme } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AnimatePresence } from "framer-motion"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useReducer, useEffect, useRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { PiPathFill } from "react-icons/pi"
import { RxCross2 } from "react-icons/rx"
import { invade, support } from "../../api/world"
import { FaCopy } from "react-icons/fa"

import {
    useConfig,
    useEffects,
    useFactions,
    useGameId,
    useInvasionInfo,
    useIsUnlocked,
    useLeaderRole,
    usePlayer,
    useResources,
} from "../../hooks/query"
import { UISpendResources, useIsMobile, useIsVisible } from "../../hooks/utils"
import { useStore } from "../../store"
import { Factions, ResourceType, SupportType, TerrainType, UnlockType } from "../../types"
import Button from "../common/Button"
import DateComponent from "../common/DateComponent"
import GameIcon from "../common/GameIcon"
import HelpText from "../common/HelpText"
import { AnimatedBox } from "../common/Motion"
import ModList from "../resources/ModList"
import Supports from "./components/Supports"
import Countdown from "react-countdown"
import Supply from "./components/Supply"
import TileFortification from "./components/TileFortification"
import TileImprovement, { TileImprovements } from "./components/TileImprovement"
import Pillage from "./components/Pillage"
import Number from "../common/Number"
import LeaderPanel from "./components/LeaderPanel"
import { LeaderRole } from "../../api/faction"
import hotkeys from "hotkeys-js"
import { TileInfo } from "../../store/MapStore"
import BattleLog from "./BattleLog"

type MobileSelectedTileProps = {
    children: React.ReactNode
}

const DrawerSelectedTile: FunctionComponent<MobileSelectedTileProps> = observer(({ children }) => {
    const { mapStore } = useStore()

    return (
        <AnimatePresence>
            {mapStore.selectedTile && (
                <AnimatedBox
                    initial={{ opacity: 0, bottom: -140 }}
                    animate={{ opacity: 1, bottom: 33 }}
                    exit={{ opacity: 0, bottom: -140 }}
                    position="fixed"
                    left="0"
                    right="0"
                >
                    <IconButton
                        aria-label="close"
                        size="sm"
                        icon={<CloseIcon />}
                        position="absolute"
                        right="-1"
                        top="-1"
                        onClick={() => mapStore.unselectTile()}
                        zIndex={1}
                    />
                    <Box pointerEvents={"auto"}>{children}</Box>
                    
                </AnimatedBox>
                
            )}
        </AnimatePresence>
        // <Box position="fixed" bottom="40px" left="0" right="0" bgColor="white">
        //             <SlideFade in={isOpen}>

        //     {children}
        //     </SlideFade>
        // </Box>
    )
})

const SelectedTile: FunctionComponent = observer(() => {
    const { mapStore } = useStore()

    const { t } = useTranslation()
    const mobile = useIsMobile()
    const theme = useTheme()
    const selectedTile = mapStore.selectedTile
    const showGuardian = useIsUnlocked(UnlockType.GUARDIAN_TRAINING_CENTER)
    const showKnight = useIsUnlocked(UnlockType.KNIGHT_TRAINING_CENTER)
    const tileImprovementUnlocked = useIsUnlocked(UnlockType.UI_TILE_IMPROVEMENT)
    const tileFortificationUnlocked = useIsUnlocked(UnlockType.UI_TILE_FORTIFICATION)
    const { data: effects } = useEffects()
    const player = usePlayer()

    // const { data: tileInfo, isLoading: isLoadingTileInfo } = useTileInfo(
    //     selectedTile?.x,
    //     selectedTile?.y,
    //     mapStore.runFetch
    // )
    let tileInfo: TileInfo | null = null

    if (selectedTile) tileInfo = mapStore.getTileInfo(selectedTile!.x, selectedTile!.y)

    const ownTile = tileInfo?.faction === player?.faction
    const showTileFortification = tileInfo?.fortificationDetails.visible && (!ownTile || tileFortificationUnlocked)
    const showTileImprovement =
        tileInfo?.improvements.some((item) => item.visible) && (!ownTile || tileImprovementUnlocked)

    // const { isLoading: isLoadingInvasion } = useInvasionInfo()

    const resources = useResources()
    const config = useConfig()
    const gameId = useGameId()

    // send soldiers
    const invasion = useMutation({
        mutationFn: (soldiers: number) => invade(gameId, selectedTile!.x, selectedTile!.y, soldiers),
        onMutate: (soldiers: number) => {
            UISpendResources({ gameId, resources: { soldiers } })
        },
        retry: 2,
    })

    // send guardian or knight
    const supportMutation = useMutation({
        mutationFn: (supportType: SupportType) => support(gameId, selectedTile!.x, selectedTile!.y, supportType),
        onMutate: (supportType: SupportType) => {
            UISpendResources({ gameId, resources: { [supportType]: 100 } })
        },
        retry: 2,
    })

    const { data: factions } = useFactions()

    const terrainType = selectedTile ? config?.world[selectedTile!.x][selectedTile!.y] : null
    const terrainTypeBonus = terrainType ? config?.mapConfig.terrains_bonus[terrainType] : null

    const leaderRoles = useLeaderRole()
    const showLeaderIcons = leaderRoles ? [LeaderRole.Leader, LeaderRole.General].includes(leaderRoles) : false

    const Wrapper = mobile ? DrawerSelectedTile : Box

    let faction: Factions | null = null
    let soldiers: number | null = null
    let supplied: boolean | null = null
    let factionName: string | null | undefined = null
    let toSupportSoldiers: number | null = null
    let defenseColor = "normal"

    if (tileInfo) {
        faction = tileInfo.faction
        soldiers = tileInfo.soldiers || 0
        supplied = tileInfo.supplied
        factionName = factions ? factions?.find((f) => f.id === faction)?.name : faction

        // compute number of soldiers you need to send to gain support (share of defense soldiers and attack soldiers)
        let toSupportSoldiersOnDefense = 0
        let toSupportSoldiersOnAttack = 0
        toSupportSoldiers = 0
        if (faction === player?.faction && soldiers < 10) toSupportSoldiersOnDefense = 10 - soldiers
        else if (faction !== player?.faction) {
            toSupportSoldiersOnAttack = soldiers
            toSupportSoldiersOnDefense = 10
        } else if (!faction) toSupportSoldiersOnDefense = 10
        if (effects) {
            toSupportSoldiersOnDefense = toSupportSoldiersOnDefense / (1 + effects?.world.defense.total / 100)
            if (toSupportSoldiersOnAttack) {
                toSupportSoldiersOnAttack =
                    toSupportSoldiersOnAttack / (1 + (effects?.world.attack.total + tileInfo.attack) / 100)
                if (tileInfo.fortification < 100)
                    toSupportSoldiersOnAttack = toSupportSoldiersOnAttack / (1 - tileInfo.fortification / 100)
                else toSupportSoldiersOnAttack = Infinity
            }
            toSupportSoldiers = toSupportSoldiersOnAttack + toSupportSoldiersOnDefense
        }
        toSupportSoldiers = Math.ceil(toSupportSoldiers)

        if (ownTile) {
            if (tileInfo.fortification < 0) defenseColor = "red"
            else if (tileInfo.fortification > 0) defenseColor = "green"
        } else {
            if (tileInfo.fortification < 0) defenseColor = "green"
            else if (tileInfo.fortification > 0) defenseColor = "red"
        }
    }

    useEffect(() => {
        if (tileInfo?.isValidForInvasion.valid) {
            hotkeys("f", function (event, handler) {
                if (resources.soldiers < 1) return
                invasion.mutate(1)
            })
            hotkeys("g", function (event, handler) {
                if (resources.soldiers < 10) return
                invasion.mutate(10)
            })
            hotkeys("h", function (event, handler) {
                if (resources.soldiers < 100) return
                invasion.mutate(100)
            })
            if (toSupportSoldiers) {
                hotkeys("j", function (event, handler) {
                    if (resources.soldiers < toSupportSoldiers!) return
                    invasion.mutate(toSupportSoldiers!)
                })
            }
        }
        return () => {
            hotkeys.unbind("f,g,h,j")
        }
    }, [tileInfo?.x, tileInfo?.y, resources.soldiers, tileInfo?.isValidForInvasion.valid, toSupportSoldiers])

    if (!selectedTile || !tileInfo) return <></>

    return (
        <Wrapper aria-live="assertive">
            <Box
                minHeight="200"
                maxHeight={[250, 250, "none"]}
                overflowY={["scroll", "scroll", "auto"]}
                w="full"
                bgColor={faction && faction !== Factions.NEUTRAL ? faction : "orange.800"}
            >
                <>
                    <Stack justifyContent={"space-around"} p={[1, 1, 3]} gap={[0, 0, 2]}>
                        <HStack justifyContent={"space-between"} height="6">
                            <HStack>
                                {faction && faction !== Factions.NEUTRAL && (
                                    <>
                                        {supplied ? (
                                            <HelpText
                                                tooltip="This territory is connected to the faction's HQ"
                                                showHelpButton={false}
                                            >
                                                <Icon as={PiPathFill} color="green.500" />
                                            </HelpText>
                                        ) : (
                                            <HelpText
                                                tooltip="This territory is not connected to the faction's HQ. This means that every 5 minutes, 2% of its soldiers will die."
                                                showHelpButton={false}
                                            >
                                                <Icon as={RxCross2} color="red.500" />
                                            </HelpText>
                                        )}
                                    </>
                                )}
                                <Text fontSize="xs">
                                    {terrainType && (
                                        <>
                                            {terrainType === TerrainType.CASTLE ? (
                                                <HelpText
                                                    tooltip={
                                                        "You need to capture all the castle tile and hold it 12 hours to win."
                                                    }
                                                >
                                                    {t(`terrain.${terrainType}`)}
                                                </HelpText>
                                            ) : (
                                                <>{t(`terrain.${terrainType}`)}</>
                                            )}
                                        </>
                                    )}
                                </Text>
                            </HStack>
                            <HStack justify={"flex-end"}>
                                <Text fontSize="xs" pr={[10, 10, 10, 4]}>
                                    X : {selectedTile!.x} - Y : {selectedTile!.y}
                                </Text>
                                <IconButton
                                    variant={"ghost"}
                                    aria-label="copy"
                                    size="xs"
                                    icon={<FaCopy />}
                                    title="Copy coordinates to clipboard"
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${selectedTile!.x}:${selectedTile!.y}`)
                                    }}
                                />
                            </HStack>
                        </HStack>

                        {terrainTypeBonus && <ModList value={terrainTypeBonus} />}

                        {tileInfo.lostSupplyDate && tileInfo.faction != Factions.NEUTRAL && (
                            <Supply lostSupplyDate={tileInfo.lostSupplyDate} />
                        )}

                        <Divider />

                        <Stack>
                            <Stack justifyContent={"center"} gap="0" height="12">
                                {faction ? (
                                    <>
                                        <HStack justifyContent="center" alignItems="center">
                                            <Text>{factionName}</Text>
                                        </HStack>

                                        <HStack justifyContent="center" alignItems="center">
                                            <Text>Soldiers</Text>

                                            <Text>
                                                <GameIcon name={ResourceType.SOLDIER} />
                                                {soldiers}
                                            </Text>
                                        </HStack>
                                    </>
                                ) : (
                                    <HStack justifyContent={"center"} alignItems={"center"}>
                                        <Text>Neutral</Text>
                                    </HStack>
                                )}
                            </Stack>
                        </Stack>

                        {tileInfo.victoryPoints && <Pillage tileInfo={tileInfo} />}

                        <Divider />

                        {showLeaderIcons && (
                            <>
                                <LeaderPanel tileInfo={tileInfo} />
                                <Divider />
                            </>
                        )}

                        <Box>
                            {!tileInfo && <Skeleton height="30px" />}

                            <>
                                <HStack justifyContent={"space-between"} alignItems={"center"}>
                                    <Box>
                                        {tileInfo?.isValidForInvasion.valid && (
                                            <>
                                                Send <GameIcon name={ResourceType.SOLDIER} /> soldiers{" "}
                                            </>
                                        )}
                                    </Box>

                                    {tileInfo.faction && tileInfo.faction !== Factions.NEUTRAL && (
                                        <Box textAlign="center">
                                            <HelpText tooltip="Fortification of this terrain. For example, if the bonus is 50%, then only half the soldier sent will be effective. Next to your HQ, you get a home fortification bonus.">
                                                FORT.
                                            </HelpText>
                                            <Number
                                                value={tileInfo.fortification}
                                                percentage
                                                color={defenseColor}
                                                ml="2"
                                            />
                                        </Box>
                                    )}
                                    {tileInfo.homeBonus?.value && (
                                        <Box bgColor={theme.app.factions[tileInfo.homeBonus?.faction]} textAlign="center">
                                            <HelpText tooltip={`${tileInfo.homeBonus?.faction} has a home fortification bonus of ${tileInfo.homeBonus?.value}%. They can attack and defend with a bonus.`}>
                                                <>{tileInfo.homeBonus?.faction} FORT.</>
                                            </HelpText>
                                            <Number
                                                value={tileInfo.homeBonus?.value}
                                                percentage
                                                color={defenseColor}
                                                ml="2"
                                            />
                                        </Box>
                                    )}
                                </HStack>
                                {tileInfo?.isValidForInvasion.valid && (
                                    <>
                                        <HStack gap="0" justifyContent={"center"}>
                                            <Button
                                                onClick={() => {
                                                    invasion.mutate(1)
                                                }}
                                                tooltip={"Hotkey: F"}
                                                isDisabled={resources.soldiers < 1}
                                                isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                                margin="0.5"
                                                p="2"
                                            >
                                                +1
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    invasion.mutate(10)
                                                }}
                                                tooltip={"Hotkey: G"}
                                                isDisabled={resources.soldiers < 10}
                                                isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                                margin="0.5"
                                                p="2"
                                            >
                                                +10
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    invasion.mutate(100)
                                                }}
                                                tooltip={"Hotkey: H"}
                                                isDisabled={resources.soldiers < 100}
                                                isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                                margin="0.5"
                                                p="2"
                                            >
                                                +100
                                            </Button>
                                        </HStack>

                                        {(showGuardian || showKnight) && (
                                            <HStack gap="0" justifyContent={"center"} wrap={"wrap"}>
                                                <Button
                                                    tooltip={
                                                        "Send enough soldiers to get support (10 soldiers of your faction). Hotkey: J"
                                                    }
                                                    onClick={() => {
                                                        invasion.mutate(toSupportSoldiers!)
                                                    }}
                                                    isDisabled={
                                                        resources.soldiers < toSupportSoldiers! ||
                                                        toSupportSoldiers === 0
                                                    }
                                                    isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                                    margin="0.5"
                                                    p="2"
                                                >
                                                    +{toSupportSoldiers}
                                                </Button>
                                                <Button
                                                    tooltip={"Send all your soldiers."}
                                                    onClick={() => {
                                                        invasion.mutate(Math.floor(resources.soldiers))
                                                    }}
                                                    isDisabled={resources.soldiers < 1}
                                                    isLoading={invasion.failureCount >= 1 && invasion.isPending}
                                                    margin="0.5"
                                                    p="2"
                                                >
                                                    +{Math.floor(resources.soldiers)}
                                                </Button>
                                                {showGuardian && (
                                                    <Button
                                                        onClick={() => {
                                                            supportMutation.mutate(SupportType.GUARDIAN)
                                                        }}
                                                        isLoading={supportMutation.isPending}
                                                        colorScheme="green"
                                                        isDisabled={
                                                            resources.guardian < 100 ||
                                                            !tileInfo?.isValidForGuardian.valid
                                                        }
                                                        margin="0.5"
                                                        p="2"
                                                    >
                                                        Send <GameIcon name={ResourceType.GUARDIAN} />
                                                    </Button>
                                                )}
                                                {showKnight && (
                                                    <Button
                                                        onClick={() => {
                                                            supportMutation.mutate(SupportType.KNIGHT)
                                                        }}
                                                        isLoading={supportMutation.isPending}
                                                        colorScheme="green"
                                                        isDisabled={
                                                            resources.knight < 100 ||
                                                            !tileInfo?.isValidForKnight.valid ||
                                                            !faction
                                                        }
                                                        margin="0.5"
                                                        p="2"
                                                    >
                                                        <Box display="flex" alignItems="center">
                                                            Send <GameIcon name={ResourceType.KNIGHT} />
                                                        </Box>
                                                    </Button>
                                                )}
                                            </HStack>
                                        )}
                                    </>
                                )}
                            </>

                            {tileInfo && (
                                <>
                                    <Supports tileInfo={tileInfo} />
                                </>
                            )}

                            {tileInfo && !tileInfo?.isValidForInvasion.valid && (
                                <Box layerStyle={"info"} color="orange.800" boxShadow={"md"}>
                                    <Text color="orange.800">You can't invade here</Text>
                                    {tileInfo?.isValidForInvasion.reason === "forbidden" && (
                                        <Text variant="discreet" color="orange.800">
                                            This terrain is not passable.
                                        </Text>
                                    )}
                                    {tileInfo?.isValidForInvasion.reason === "no support" && (
                                        <Text variant="discreet" color="orange.800">
                                            To invade this territory, it needs to have an adjacent territory with at
                                            least 10 soldiers of your faction.
                                        </Text>
                                    )}
                                    {tileInfo?.isValidForInvasion.reason === "no supply route" && (
                                        <Text variant="discreet" color="orange.800">
                                            This territory is not supplied.
                                        </Text>
                                    )}
                                    {tileInfo?.isValidForInvasion.reason === "hq" && (
                                        <Text variant="discreet" color="orange.800">
                                            You can't invade a faction castle.
                                        </Text>
                                    )}
                                </Box>
                            )}
                        </Box>

                        {(invasion.error && !invasion.isPending) ||
                            (supportMutation.error && !supportMutation.isPending && (
                                <Box layerStyle={"error"}>
                                    <Text>An error occurred...</Text>
                                </Box>
                            ))}

                        {showTileFortification && (
                            <>
                                <Divider my="2" />
                                <TileFortification />
                                {!showTileImprovement && <Divider my="2" />}
                            </>
                        )}

                        {showTileImprovement && (
                            <>
                                <Divider my="2" />
                                <TileImprovements />
                                <Divider my="2" />
                            </>
                        )}

                        {tileInfo?.captureDate && tileInfo.capturePlayer && (
                            <Center fontSize="xs">
                                <Stack alignItems={"center"} gap="0">
                                    <Text>
                                        {faction ? "Captured" : "Neutralized"} by : {tileInfo?.capturePlayer}
                                    </Text>
                                    <Text>
                                        on <DateComponent date={tileInfo.captureDate} />
                                    </Text>
                                </Stack>
                            </Center>
                        )}
                    </Stack>
                    {mobile && <BattleLog />}
                </>
            </Box>
           
        </Wrapper>
    )
})

export default SelectedTile
